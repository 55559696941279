class DbImport {
  static bindConfirmImport(source) {
    if (!source) {
      return;
    }
    let dialog = document.querySelector("#confirm-import-dialog");
    source.onclick = () => {
      dialog.open();
    };

    dialog.addEventListener('iron-overlay-closed', (event) => {
      if (event.detail.confirmed) {
        document.querySelector("#import-staging-form").submit();
      }
    });
  }

  static bindSendImportNotification(source) {
    if (!source) {
      return;
    }
    let dialog = document.querySelector("#import-notification-dialog");
    source.onclick = () => {
      dialog.open();
    };

    dialog.addEventListener('iron-overlay-closed', (event) => {
      if (event.detail.confirmed) {
        let databases = document.querySelector("#import-notification-dialog paper-listbox").selectedValues;
        document.getElementById('notify-databases').value = databases.join(',');
        document.querySelector("#import-notification-form").submit();
      }
    });
  }
}

export {DbImport};
