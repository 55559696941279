// https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest
class AjaxRequest {
  constructor(opts={}) {
    let self = this;
    self.async = opts.async || true;
    self.method = opts.method;
    self.url = opts.url;
    self.data = opts.data;
    self.success = opts.success;
    self.failure = opts.failure;
    self.always = opts.always;

    let req = new XMLHttpRequest();
    req.onload = function(){
      if (req.readyState === XMLHttpRequest.DONE) {
        switch (req.status) {
        case 200:
          if (typeof self.success === 'function') {
            self.success(req);
          }
          break;
        default:
          if (typeof self.failure === 'function') {
            self.failure(req);
          }
        }
        if (typeof self.always === 'function') {
          self.always(req);
        }
      }
    };
    self.httpRequest = req;
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/open
  fetch() {
    let self = this;

    if (self.method === "GET") {
      self.url += `?${Object.keys(self.data).map(key => key + '=' + self.data[key]).join('&')}`;
      self.httpRequest.open(self.method, self.url, self.async);
      self.httpRequest.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      self.httpRequest.send();
    } else {
      self.httpRequest.open(self.method, self.url, self.async);
      self.httpRequest.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      self.httpRequest.send(Object.keys(self.data).map(key => key + '=' + self.data[key]).join('&'));
    }
  }
}
export {AjaxRequest};
