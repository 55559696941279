class RN {
  static removeClass(htmlClass, source) {
    let items = (source || document).querySelectorAll('.'+htmlClass);
    for (let i = 0; i < items.length; i++) {
      items[i].classList.remove(htmlClass);
    }
  }

  static bindMdcElements(htmlClass, MDCKlass, source) {
    let items = (source || document).querySelectorAll(htmlClass.startsWith('.') ? htmlClass : '.'+htmlClass);
    let elements = [];
    for (let i = 0; i < items.length; i++) {
      elements.push(new MDCKlass(items[i]));
    }
    return elements;
  }

  static bindInputToAwesomplete(className) {
    const Http = new XMLHttpRequest();
    const url='/sites';
    Http.open("GET", url);
    Http.setRequestHeader('Accept', 'application/json');
    Http.onreadystatechange=() => {
      if (Http.readyState == 4 && Http.status == 200) {
        let sites = JSON.parse(Http.responseText);
        let inputs = document.getElementsByClassName(className);

        for (var i = 0; i < inputs.length; i++) {
          let input = inputs[i]
          new Awesomplete(input, {
            list: Object.keys(sites)
          });

          let hiddenField = input.getElementsByClassName("site-ids-hidden")[0]

          if (hiddenField.value) {
            let url = Object.keys(sites).find(key => sites[key] === parseInt(hiddenField.value));
            input.value = url;
          }

          input.onchange = function() {
            hiddenField.value = sites[input.value];
          }

          document.addEventListener("awesomplete-selectcomplete", function() {
            hiddenField.value = sites[input.value];
          });
        }
      }
    };
  }

  static bindFormSubmits(source) {
    let formSubmits = (source || document).querySelectorAll('.form-submit');
    formSubmits.forEach(function(element) {
      element.addEventListener('click', function(event){
        event.preventDefault();
        event.target.closest('iron-form').submit();
      });
    });

    let forms = (source || document).querySelectorAll('iron-form');
    forms.forEach(function(element) {
      element.addEventListener('keyup', function(event){
        if (event.which == 13) {
          event.target.closest('iron-form').submit();
        }
      });
    });
  }

  static bindSelectChange(source, callback) {
    if (source !== null) {
      let selects = source.querySelectorAll('paper-listbox');

      selects.forEach(function(element) {
        element.addEventListener('selected-changed', function(event){
          event.preventDefault();

          let value = event.target.selectedItem.getAttribute("value");
          let hiddenField = event.target.querySelector('.form-select');
          if (hiddenField !== null) {
            hiddenField.setAttribute("value", value);
          }

          if (typeof callback === 'function') {
            callback.call(this, event);
          }
        });
      });
    }
  }

  static bindMultiSelectChange(source, callback) {
    if (source !== null) {
      let selects = source.querySelectorAll('paper-item');

      selects.forEach(function(element) {
        element.addEventListener("click", function(event){
          event.preventDefault();

          let value = event.target.getAttribute("value");
          let hiddenField = event.target.querySelector(".form-select");

          if (hiddenField !== null) {
            hiddenField.value = value;
          }

          if (typeof callback === 'function') {
            callback.call(this, event);
          }
        });
      });
    }
  }

  static bindChipChange(source) {
    let chips = (source || document).querySelectorAll('.mdc-chip-set .mdc-chip');

    chips.forEach(function(element) {
      element.addEventListener('click', function(event){
        let chipFilter = event.target.closest(".mdc-chip").querySelector(".form-chip-filter");

        if (RN.findAncestor(event.target, "mdc-chip--selected")) {
          if (chipFilter.type === "checkbox") {
            chipFilter.checked = false;
          } else {
            chipFilter.setAttribute("value", '');
          }
        } else {
          if (chipFilter.type === "checkbox") {
            chipFilter.checked = true;
          } else {
            chipFilter.setAttribute("value", chipFilter.getAttribute("data-id"));
          }
        }
      });
    });
  }

  static scrollTo(element, to, duration) {
    if (duration <= 0) {
      return;
    }
    let difference = to - element.scrollTop;
    let perTick = difference / duration * 10;

    setTimeout(function() {
      element.scrollTop += perTick;
      if (element.scrollTop === to) {
        return;
      }
      RN.scrollTo(element, to, duration - 10);
    }, 10);
  }

  static findAncestor(element, klass) {
    let el = element;
    while (el && !el.classList.contains(klass)) {
      el = el.parentElement;
    }
    return el;
  }

  static capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  static titleize(str) {
    let strAry = str.replace('_', ' ').split(' ');
    strAry = strAry.map(function(s) {
      return RN.capitalize(s);
    });

    return strAry.join(' ');
  }

  static hideElements(wrapper, htmlClass) {
    let items = wrapper.querySelectorAll(htmlClass);
    for (let i = 0; i < items.length; i++) {
      items[i].style.display = 'none';
    }
  }

  static bindDocumentEvent(selector, eventType, f) {
    document.addEventListener(eventType, function(e) {
      if (e.target.matches(selector)) {
        f(e);
      }
    });
  }
}
export {RN};
