import {AjaxRequest} from 'js/ajax-request';

class SimpleHistory {
  constructor(opts={}) {
    let self = this;
    let simpleHistoryArray = window.simpleHistory || []
    window.simpleHistory = simpleHistoryArray;
    window.simpleHistory.push(self);

    self.rebindPage = opts.rebindPage || function(){};
    self.dataSelector = opts.dataSelector || 'data-push';
    self.defaultId = opts.defaultId || '#results';
    self.progress = opts.progress || '#ajax-progress';
    self.overlay = opts.overlay || '';
    self.stateChangeEvent = "statechange.simpleHistory."+window.simpleHistory.length;

    self.bindStateChangeEvent();
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/open
  bindStateChangeEvent() {
    let self = this;

    window.addEventListener(self.stateChangeEvent, function(e){
      document.querySelector(self.progress).style.display = 'block';
      document.querySelector(self.overlay).style.display = 'block';

      if (history.state && !history.state.content) {
        let data = history.state.data || {};
        let search = document.location.search.substring(1);
        let searchData = JSON.parse('{"' + decodeURI(search.replace(/&/g, "\",\"").replace(/=/g,"\":\"")) + '"}')
        searchData.ajax = true;
        searchData.format = "html";

        let ajaxRequest = new AjaxRequest({
          url: document.location.pathname,
          method: "GET",
          data: Object.assign(data, searchData),
          success: function(data){
            let oldState = history.state;
            // replace the current state so we can get back to it
            history.replaceState({
              content: data.response,
              partial: oldState.partial,
              stateChangeEvent: self.stateChangeEvent
            }, document.title, document.location.href);

            document.querySelector(history.state.partial).innerHTML = data.response;
            self.rebindPage();
          },
          failure: function(jqXHR, textStatus, errorThrown){
            if (history.state.errorPartial) {
              document.querySelector(history.state.errorPartial).innerHTML = `<div class="alert alert-danger">'${history.state.errorMessage}</div>`;
            }
          },
          always: function(){
            document.querySelector(self.progress).style.display = 'none';
            document.querySelector(self.overlay).style.display = 'none';
          }
        });
        ajaxRequest.fetch();
      } else {
        document.querySelector(history.state.partial).innerHTML = history.state.content;
        self.rebindPage();
        document.querySelector(self.progress).style.display = 'none';
        document.querySelector(self.overlay).style.display = 'none';
      }
    });

    if (window.simpleHistory.length === 1) {
      window.addEventListener('popstate', function() {
        if (history.state && history.state.content && history.state.stateChangeEvent) {
          let event = document.createEvent('HTMLEvents');
          event.initEvent(history.state.stateChangeEvent, true, false);
          window.dispatchEvent(event);
        }
      });
    }

    self.bindHistoryDomEvent('click', function(item){
      return item.getAttribute('href');
    });

    self.bindHistoryDomEvent('submit', function(item){
      let formAction = item.getAttribute("action") || document.location.pathname;

      return `${formAction}?${item.serialize()}`;
    });
  }

  bindHistoryDomEvent(eventName, getUrl){
    let self = this;

    document.addEventListener(eventName, function(e){
      if (e.target.matches(`a[${self.dataSelector}]`) || e.target.matches(`form[${self.dataSelector}]`)) {
        e.preventDefault();

        let partial = e.target.getAttribute('data-partial') ? e.target.getAttribute('data-partial') : self.defaultId;

        debugger;

        if (e.target.getAttribute('data-overlay') !== null){
          self.triggerOverlay(partial);
        }

        self.setCurrentState(partial, self.stateChangeEvent);

        history.pushState({
          content: null,
          partial: partial,
          overlay: e.target.getAttribute('data-overlay'),
          errorPartial: e.target.getAttribute('data-errorPartial'),
          errorMessage: e.target.getAttribute('data-errorMessage'),
          stateChangeEvent: self.stateChangeEvent
        }, document.title, getUrl(e.target));

        let event = document.createEvent('HTMLEvents');
        event.initEvent(self.stateChangeEvent, true, false);
        window.dispatchEvent(event);
      }
    });
  }

  supportsHistoryApi() {
    return !!(window.history && history.pushState);
  }

  setCurrentState(partial, stateCE) {
    if (!history.state || !history.state.content) {
      history.replaceState({
        content: document.querySelector(partial).innerHTML,
        partial: partial,
        stateChangeEvent: stateCE
      }, document.title, document.location.href);
    }
  }

  triggerOverlay(partial) {
    let self = this;
    let partialObj = document.querySelector(partial);
    let rect = partialObj.getBoundingClientRect();
    let position = {
      top: rect.top + document.body.scrollTop,
      left: rect.left + document.body.scrollLeft
    };

    if (position !== null) {
      let overlay = document.querySelector(self.overlay);
      overlay.style.width = getComputedStyle(partialObj)["width"];
      overlay.style.height = getComputedStyle(partialObj)["height"];
      overlay.style.top = `${position.top}px`;
      overlay.style.left = `${position.left}px`;
    }
  }
}

export {SimpleHistory};
