import {RN} from './plugins/rn';

RN.bindSelectChange(document.querySelector("#deployments"), function(event){
  let target = event.target.selectedItem.getAttribute("data-target");
  document.querySelector(target).parentElement.style.display = '';
  if (target !== null) {
    document.querySelectorAll(target).forEach(function(element) {
      element.removeAttribute("disabled");
      element.style.display = "block";
    });
  }
});

RN.bindMultiSelectChange(document.querySelector("#deployments paper-listbox[multi]"));
