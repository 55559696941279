import Rails from '@rails/ujs';
import {SimpleHistory} from './plugins/simple-history';
import {RN} from './plugins/rn';
import {DbImport} from './plugins/db-import.js';
import {MDCChipSet} from '@material/chips';
import './deployment';
import {addRoleButtonToggles} from './role';

Rails.start();

window.SH = {};

window.SH.simpleHistory = new SimpleHistory({
  defaultId: '#results',
  overlay: "#ajax-overlay"
});

RN.bindFormSubmits();
RN.bindChipChange();
RN.bindSelectChange(document.getElementById("new_deployment"));
RN.bindSelectChange(document.getElementById("new_role"));
RN.bindSelectChange(document.getElementById("audit"));
RN.bindInputToAwesomplete('awesomplete-sites');

DbImport.bindSendImportNotification(document.querySelector("#imports #send-import-notification"));
DbImport.bindConfirmImport(document.querySelector("#imports #import-staging"));

if (document.getElementById("dismiss-flash") !== null) {
  document.getElementById("dismiss-flash").addEventListener("click", function(e) {
    let el = e.target;

    while (!el.classList.contains("flash-message-card")) {
      el = el.parentElement;
    }

    el.style.display = "none";
  });
}

if (document.querySelector('.mdc-chip-set') !== null) {
  window.mdcChipset = new MDCChipSet(document.querySelector('.mdc-chip-set'));
}

addRoleButtonToggles();
