/**
*
*/
function revealRoles(form, glyph) {
  form.style.display = "initial";
  glyph.textContent = "keyboard_arrow_up";
}

/**
*
*/
function hideRoles(form, glyph) {
  form.style.display = "none";
  glyph.textContent = "keyboard_arrow_down";
}

/**
*
*/
export function addRoleButtonToggles() {
  const buttons = document.querySelectorAll(".mdc-button.mdc-button--dense.role-group-header");

  for (let i = 0; i < buttons.length; i++) {
    const button = buttons[i];
    button.addEventListener('click', (_e) => {
      const form = button.nextElementSibling;
      const glyph = button.lastElementChild;
      if (form.style.display === 'none') {
        revealRoles(form, glyph);
      } else {
        hideRoles(form, glyph);
      }
    });
  }
}
